<template>
  <div class='px-2 lg:px-0'>
    <div class='px-2 lg:px-0 py-4 relative'>
      <button 
        @click='goToSessionVotings'
        :class='themeTextColorStyle'>
        <arrow-narrow-left-icon class='inline-block h-4' /> 
        See All Questions
      </button>
      <div class='mt-8 flex flex-row justify-between gap-x-4'>
        <h1 class='text-xl font-medium text-gray-900'>{{session1Title}}</h1>
        <div class='flex flex-row justify-end items-stretch gap-x-1'>
          <select 
            v-model='comparisonQuestionId'
            class='border border-gray-200 rounded-md px-2 text-xs capitalize py-2' 
            style='max-width:50%;'>
            <option v-for='question in questionsForSession'
              :key='`question-questions-${question.id}`'
              :value='question.id'>
              {{ question.question }}
            </option>
          </select>
          <button
            class='text-xs uppercase py-2 px-2 bg-gray-50 border border-gray-600 rounded-md'
            @click='addComparisonQuestion'>
            Compare
          </button>
        </div>
      </div>
      <content-background-cover
        background-color='#FAFAFA'
        class='z-0 border-b' />
    </div>
    <div class='px-2 lg:px-0 pt-8 pb-16 flex flex-row justify-between gap-x-6'>
      <session-voting-result-single 
        :session-voting-question-id='sessionVotingId'
        :class='sessionCountBasedLayout'
        @update-session='updateSession1' />
      <session-voting-result-single v-if='sessionVotingIdCompare'
        :session-voting-question-id='sessionVotingIdCompare'
        :key='`session-voting-comparison-${sessionVotingIdCompare}`'
        :class='sessionCountBasedLayout' />
    </div>
  </div>
</template>

<script>
import ContentBackgroundCover       from '@/components/ContentBackgroundCover.vue'
import SessionVotingResultSingle    from '@/components/SessionVotingResultSingle.vue'

import { ArrowNarrowLeftIcon } from 'vue-tabler-icons'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'SessionVotingResult',
  components: {
    ArrowNarrowLeftIcon,
    ContentBackgroundCover,
    SessionVotingResultSingle,
  },
  data () {
    return {
      session1: null,
      questionsForSession: [],
      sessionVotingIdCompare: 0,
      comparisonQuestionId: 0,
    }
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
      'showingEventId',
      'colorsForGraph',
    ]),
    sessionCountBasedLayout () {
      return (this.sessionVotingIdCompare) ? 'w-1/2 flex-shrink-0 flex-grow-0' : 'w-full'
    },
    sessionVotingId () {
      return parseInt(this.$route.query.session_voting_id)
    },
    session1Title () {
      return (this.session1) ? this.session1.title : ''
    },
  },
  methods: {
    ...mapActions('sessionVoting', [
      'getSingleSessionVotingQuestions',
    ]),
    goToSessionVotings () {
      let query = Object.assign({}, this.$route.query)
      delete query.session_voting_id
      this.$router.push({ name: 'SessionVotingResults', query: query})
    },
    updateSession1 (sessionData) {
      this.session1 = sessionData
      this.getSingleSessionVotingQuestions(this.session1.id).then(response => {
        console.log('session questions', response)
        this.questionsForSession = response.filter(question => question.id !== this.sessionVotingId)
      })
    }, 
    addComparisonQuestion () {
      this.sessionVotingIdCompare = this.comparisonQuestionId
    },
  },
  mounted () {
  },
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>