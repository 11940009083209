<template>
    <div>
      <div class='py-0.5 flex flex-row justify-start items-start gap-x-4 pl-2 mb-12'>
        <label class='block flex-shrink-0 flex-grow-0 text-center font-medium text-gray-800 w-10 h-10 py-2 rounded-md bg-gray-100 uppercase'>q</label>
        <p class='text-sm lg:text-normal font-semibold uppercase text-gray-900 whitespace-pre-line pt-2.5'>{{ questionText }}</p>
      </div>
      <v-chart
        class='chart mb-12' 
        :option='chartOptions' 
        autoresize />
      <session-voting-answer-finished v-for='(choice, index) in sessionVotingChoices' 
        :key='choice.id'
        :index='index'
        :choice-data='choice'
        :choice-result-data='sessionVotingChoiceResults'
        :color='colorsForGraph[index]' />
    </div>
</template>

<script>
import SessionVotingAnswerFinished  from '@/components/SessionVotingAnswerFinished.vue'

import { use } from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart } from 'echarts/charts'
import {
  TitleComponent,
  TooltipComponent,
} from 'echarts/components'
import VChart from 'vue-echarts'

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
])

import { mapGetters, mapActions } from 'vuex'
import cloneDeep              from 'lodash/cloneDeep'

export default {
  name: 'SessionVotingResultSingle',
  components: {
    SessionVotingAnswerFinished,
    VChart,
  },
  props: {
    sessionVotingQuestionId: {
      type: Number,
      required: true,
    },
  },
  data () {
    return {
      selectedQuestionId: 0,
      selectedVotingQuestion: {
        session: {
          title: '',
        },
        question: '',
        choices: [],
        sessionVotingResults: [],
      },
    }
  },
  computed: {
    ...mapGetters('events', [
      'themeTextColorStyle',
      'showingEventId',
      'colorsForGraph',
    ]),
    sessionTitle () {
      return this.selectedVotingQuestion ? this.selectedVotingQuestion.session.title : ''
    },
    questionText () {
      return this.selectedVotingQuestion ? this.selectedVotingQuestion.question : ''
    },
    sessionVotingChoices () {
      return this.selectedVotingQuestion ? this.selectedVotingQuestion.choices : []
    },
    sessionVotingChoicesText () {
      return this.sessionVotingChoices.map(choice => choice.text)
    },
    largestVoteGetterId () {
      let index = 0
      for (let i = 1; i < this.sessionVotingChoiceResults.length; i++) {
        if (this.sessionVotingChoiceResults[i].choicedCount > this.sessionVotingChoiceResults[index].choicedCount) {
          index = i
        }
      }
      return this.sessionVotingChoiceResults[index].choiceId
    },
    chartData () {
      return this.sessionVotingChoices.map(choice => {
        let result = this.sessionVotingChoiceResults.find(result => result.choiceId === choice.id)
        return {
          name: choice.text,
          value: (result) ? result.choicedCount : 0,
          selected: choice.id === this.largestVoteGetterId,
        }
      })
    },
    sessionVotingChoiceResults () {
      return this.selectedVotingQuestion
            ? cloneDeep(this.selectedVotingQuestion.sessionVotingResults).sort((a, b) => {
                return a.choiceId - b.choiceId
              })
            : []
    },
    chartOptions () {
      return {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {c} ({d}%)',
        },
        series: [
          {
            name: 'Traffic Sources',
            type: 'pie',
            radius: [80, 180],
            center: ['50%', '50%'],
            data: this.chartData,
            label: {
              formatter: '{b} ({d}%)'
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
        color: this.colorsForGraph,
      }
    },
  },
  methods: {
    ...mapActions('sessionVoting', [
      'getSessionVotingQuestionDataComponentStorage',
    ]),
    goToSessionVotings () {
      let query = Object.assign({}, this.$route.query)
      delete query.session_voting_id
      this.$router.push({ name: 'SessionVotingResults', query: query})
    },
  },
  mounted () {
    if (this.sessionVotingQuestionId) {
      this.selectedQuestionId = this.sessionVotingQuestionId
      this.getSessionVotingQuestionDataComponentStorage(this.selectedQuestionId).then(resp => {
        this.selectedVotingQuestion = resp
        this.$emit('update-session', resp.session)
      })
    }
  },
}
</script>

<style scoped>
.chart {
  height: 400px;
}
</style>